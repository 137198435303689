
h1, .h1 {
    font-weight: $font-weight-light;
}

h2, h3, h4, h5, h6, .h2, .h3, .h4, .h5, .h6 {
    font-weight: $font-weight-bold;
    font-family: 'Roboto Slab';
    color: $primary-color;
}

.page-header {
    margin-bottom: 100px;
}

.pagination {
    a, span {
        margin-right: 20px;    
    }
}